import { createRouter, createWebHashHistory } from "vue-router";
import BaseLayout from "@/views/layout/base_layout.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    component: BaseLayout,
    hidden: true,
    children: [
      {
        path: "/",
        component: () => import("@/views/HomeView.vue"),
        name: "home",
        meta: { title: "首页", icon: "dashboard", affix: true },
      },
      {
        path: "question-repository",
        component: () => import("@/views/questions/RepositoryView.vue"),
        name: "question-repository",
        meta: { title: "题库", icon: "dashboard", affix: true },
      },
      {
        path: "course",
        component: () => import("@/views/course/CourseView.vue"),
        name: "course",
        meta: { title: "课程", icon: "dashboard", affix: true },
      },
      {
        path: "information",
        component: () => import("@/views/article/InformationView.vue"),
        name: "information",
        meta: { title: "资讯", icon: "dashboard", affix: true },
      },
      {
        path: "user-study-center",
        component: () => import("@/views/users/StudyCenter.vue"),
        name: "user-study-center",
        meta: {
          title: "学习中心",
          icon: "dashboard",
          affix: true,
          needLogin: true,
        },
      },
      {
        path: "course-video-list/:id",
        component: () => import("@/views/course/CourseVideo.vue"),
        name: "course-video-list",
        meta: { title: "课程视频", icon: "dashboard", affix: true },
      },
      {
        path: "course-detail/:id",
        component: () => import("@/views/course/CourseDetail.vue"),
        name: "course-detail",
        meta: { title: "课程详情", icon: "dashboard", affix: true },
      },
      {
        path: "practice/:id",
        component: () => import("@/views/practice/PracticeView.vue"),
        name: "practice",
        meta: { title: "练习", icon: "dashboard", affix: true },
      },
      {
        path: "analysis/:id",
        component: () => import("@/views/practice/AnalysisView.vue"),
        name: "analysis",
        meta: { title: "解析", icon: "dashboard", affix: true },
      },
      {
        path: "error/:id",
        component: () => import("@/views/practice/ErrorView.vue"),
        name: "error",
        meta: { title: "错题集", icon: "dashboard", affix: true },
      },
      {
        path: "collect/:id",
        component: () => import("@/views/practice/CollectView.vue"),
        name: "collect",
        meta: { title: "收藏集", icon: "dashboard", affix: true },
      },
      {
        path: "article-detail/:id",
        component: () => import("@/views/article/ArticleDetail.vue"),
        name: "article-detail",
        meta: { title: "详情", icon: "dashboard", affix: true },
      },
      {
        path: "teacher-detail/:id",
        component: () => import("@/views/teacher/TeacherDetail.vue"),
        name: "teacher-detail",
        meta: { title: "详情", icon: "dashboard", affix: true },
      },
      {
        path: "search",
        component: () => import("@/views/course/SearchView.vue"),
        name: "search",
        meta: { title: "搜索", icon: "dashboard", affix: true },
      },
      {
        path: "exams",
        component: () => import("@/views/practice/ExamView.vue"),
        name: "exams",
        meta: { title: "真题", icon: "dashboard", affix: true },
      },
      {
        path: "practice-info",
        component: () => import("@/views/users/PracticeInfo.vue"),
        name: "practice-info",
        meta: {
          title: "练习信息",
          icon: "dashboard",
          affix: true,
          needLogin: true,
        },
      },
      {
        path: "change-password",
        component: () => import("@/views/users/ChangePassword.vue"),
        name: "change-password",
        meta: {
          title: "设置密码",
          icon: "dashboard",
          affix: true,
          needLogin: true,
        },
      },
      {
        path: "setting",
        component: () => import("@/views/users/SettingView.vue"),
        name: "setting",
        meta: {
          title: "设置",
          icon: "dashboard",
          affix: true,
          needLogin: true,
        },
      },
      {
        path: "about-us",
        component: () => import("@/views/page/PageAbout.vue"),
        name: "about-us",
        meta: { title: "关于我们", icon: "dashboard", affix: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(function (to, from, next) {
  // 未登录false,登录true
  if (to.meta.needLogin) {
    if (store.state.user.token) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;

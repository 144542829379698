<template>
  <Modal
    v-model="$store.state.public.showLoginModal"
    width="450px"
    footer-hide
    :closable="false"
    @on-visible-change="onOnVisibleChange"
  >
    <div class="modal-header">
      <div class="modal-title">登录 / 注册</div>
    </div>
    <div class="modal-body">
      <div class="form-item">
        <input
          v-model="formData.mobile"
          type="text"
          autocomplete="new-complete"
          @keyup.enter="doLogin"
          placeholder="手机号"
        />
      </div>
      <div class="form-item" v-if="loginType === 'code'">
        <input
          v-model="formData.msgCode"
          style="width: 200px"
          type="text"
          placeholder="验证码"
          @keyup.enter="doLogin"
        />
        <span
          class="send-btn"
          :style="{ color: isDisableBtn ? '#b1b5b9' : '#00737C' }"
          @click="sendMsg"
          >{{ sendBtnText }}</span
        >
      </div>
      <div class="form-item" v-else>
        <input
          v-model="formData.password"
          type="password"
          autocomplete="new-complete"
          placeholder="密码"
          @keyup.enter="doLogin"
        />
      </div>
      <div v-show="errorText" class="error-info-text">{{ errorText }}</div>
      <div class="form-item" style="border: none">
        <MyButton
          style="margin-top: 40px"
          width="100%"
          height="48px"
          :size="20"
          :radius="48"
          color="#ffffff"
          bgColor="#00737C"
          @onclick="doLogin"
          :disabled="loading"
          >登录</MyButton
        >
      </div>
      <div class="form-item" style="border: none; text-align: center">
        <span
          style="margin-top: 30px; cursor: pointer"
          @click="switchLoginType"
          >{{ loginType === "code" ? "账号密码登录" : "验证码登录" }}</span
        >
      </div>
      <div class="agreement-action">
        <Icon
          @click="isAgree = !isAgree"
          :type="isAgree ? 'md-radio-button-on' : 'md-radio-button-off'"
          :size="18"
        />
        <div class="agreement-action-info">
          我已阅读并同意<a class="agreement-text" href="" target="_blank"
            >《隐私政策》</a
          >
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import MyButton from "@/components/my-button/my-button.vue";
import { sendLoginMsg } from "@/api/user";

export default {
  components: {
    MyButton,
  },
  data() {
    return {
      showLoginModal: this.$store.state.public.showLoginModal,
      loginType: "code",
      isAgree: false,
      retrySeconds: -1,
      sendBtnText: "获取验证码",
      errorText: "",
      loading: false,
      formData: {
        mobile: "",
        msgCode: "",
        password: "",
      },
    };
  },
  computed: {
    isDisableBtn() {
      return this.retrySeconds > 0 || !/^\d{11}$/.test(this.formData.mobile);
    },
  },
  methods: {
    switchLoginType() {
      this.loginType = this.loginType === "code" ? "pwd" : "code";
    },
    sendMsg() {
      let that = this;
      const reg = /^\d{11}$/;
      if (!reg.test(that.formData.mobile)) {
        this.errorText = "请输入正确的手机号";
        return;
      } else {
        this.errorText = "";
      }
      if (this.loading || this.isDisableBtn) {
        return;
      }
      if (this.loginType === "code") {
        //获取验证码
        if (that.retrySeconds <= 0) {
          that.loading = true;
          sendLoginMsg({
            phone: that.formData.mobile,
            type: "web",
          })
            .then((info) => {
              if (info.code === 1) {
                that.loading = false;
                that.retrySeconds = 60;
                that.sendBtnText = `${that.retrySeconds}秒后重新获取`;
                that.msgsetInterval = setInterval(() => {
                  that.retrySeconds--;
                  that.sendBtnText = `${that.retrySeconds}秒后重新获取`;
                  if (that.retrySeconds < 1) {
                    that.sendBtnText = `重新获取`;
                    clearInterval(that.msgsetInterval);
                  }
                }, 1000);
              }
            })
            .catch((err) => {
              that.loading = false;
              this.$Message.error(err);
            });
        }
      }
    },
    onOnVisibleChange(e) {
      console.log(e);
    },
    doLogin() {
      const reg = /^\d{11}$/;
      if (!reg.test(this.formData.mobile)) {
        this.errorText = "请输入正确的手机号";
        return;
      } else {
        this.errorText = "";
      }
      if (this.loginType === "code") {
        if (!this.formData.msgCode) {
          this.errorText = "请输入验证码";
          return;
        } else if (this.formData.msgCode.length !== 6) {
          this.errorText = "请输入正确的验证码";
          return;
        }
      } else if (this.loginType === "pwd") {
        if (!this.formData.password) {
          this.errorText = "请输入密码";
          return;
        }
      }
      if (!this.isAgree) {
        this.$Message.error("请先同意隐私条款");
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      let userData = {
        mobile: this.formData.mobile,
        loginType: this.loginType,
      };
      if (this.loginType === "code") {
        userData.msgCode = this.formData.msgCode;
      } else {
        userData.password = this.formData.password;
      }
      this.$store
        .dispatch("user/login", userData)
        .then(() => {
          this.loading = false;
          this.$store.commit("public/SET_LOGIN", false);
          window.location.reload();
          this.$emit("change", false);
        })
        .catch((err) => {
          this.loading = false;
          this.err = err;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-header {
  padding: 24px 0 0;

  .modal-title {
    text-align: center;
    font-size: 26px;
    color: #00737c;
    font-weight: 400;
  }
}

.modal-body {
  padding: 4px 40px 40px;
  .error-info-text {
    margin-top: 14px;
    height: 20px;
    font-size: 14px;
    color: #ff3938;
  }
  .form-item {
    height: 63px;
    border-bottom: 1px solid rgba(222, 226, 234, 0.5);
    input {
      display: inline-block;
      width: 320px;
      color: #b1b5b9;
      height: 34px;
      border: none;
      font-size: 16px;
      padding: 6px 4px;
      text-align: left;
      position: relative;
      top: 19px;

      &::placeholder {
        color: #b1b5b9;
      }
    }

    .send-btn {
      cursor: pointer;
      background: #fff;
      width: 120px;
      height: 22px;
      font-size: 16px;
      color: #b1b5b9;
      margin-left: 16px;
      display: -webkit-inline-box;
      display: inline-flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
      top: 19px;
    }
  }
}
.agreement-action {
  display: flex;
  align-items: center;
  justify-content: center;

  .agreement-action-info {
    margin-left: 5px;
  }
}
</style>
